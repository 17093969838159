<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="tenant"
        v-model="tenant"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :get-option-label="(option) => option.name"
        :options="tenants"
        :placeholder="t('Khách hàng')"
        :disabled="!enable"
        @input="valueChanged"
        @click="onClick"
        @search="onSearchResult"
      >
        <template #option="{ name, phone }">
          {{ name }}
          <br>
          <cite>{{ phone }}</cite>
        </template>
        <template
          v-if="enableAdd === true && $can('create', 'tenant')"
          #list-header
        >
          <li
            v-b-modal.modal-tenant
            class="list-header d-flex align-items-center my-50"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">{{ t('Thêm khách hàng') }}</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          {{ t('Không có dữ liệu') }}
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <tenant-modal
      v-if="enableAdd === true"
      :item="{individual: true}"
      :is-add-lead="isAddLead"
      @refetch-data="fetchTenants"
      @on-item-created="onItemCreated"
    />
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import TenantModal from '../contract/tenant/modal/TenantModal.vue';

export default {
  components: {
    BFormGroup,
    vSelect,
    TenantModal,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    bed: {
      type: Object,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isAddLead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tenant: this.defaultValue,
      tenants: [],
      timer: null,
    };
  },
  watch: {
    room() {
      this.tenant = null;
      this.valueChanged();
      this.fetchTenants('');
    },
    apartment() {
      this.tenant = null;
      this.valueChanged();
      this.fetchTenants('');
    },
  },
  created() {
    this.fetchTenants('');
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.tenant);
    },
    async fetchTenants(searchTerm, loading) {
      if (loading) {
        loading(true);
      }
      const filter = { };

      if (this.apartment && this.apartment.id > 0) {
        filter.apartmentId = this.apartment.id;
      }
      if (this.room && this.room.id > 0) {
        filter.roomId = this.room.id;
      }
      if (this.bed && this.bed.id > 0) {
        filter.bedId = this.bed.id;
      }
      useJwt.getTenants({ filter, perPage: 20, page: 1, searchTerm: searchTerm || '' }).then(res => {
        if (loading) {
          loading(false);
        }
        this.tenants = res.data.items;
      });
    },
    async onSearchResult(searchTerm, loading) {
      await new Promise(resolve => setTimeout(resolve, 800));

      await this.fetchTenants(searchTerm, loading);
      // this.timer = setTimeout(() => {

      // }, 800);
    },
    onItemCreated(newItem) {
      this.tenant = newItem;
      this.valueChanged();
    },
    onClick() {

    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
.list-header {
    padding: $options-padding-y $options-padding-x;
      color: $secondary;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
